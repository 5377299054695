import { Loader } from "@googlemaps/js-api-loader";
import { useEffect, useRef } from "react";
import { Marker, MarkerData, newMarker } from "./marker";
import { Polyline, PolylineData, newPolyline } from "./polyline";
import { Polygon, PolygonData, newPolygon } from "./polygon";

export type Coordinates = {
   lat: number,
   lng: number,
}

type MapPosition = {
   center: Coordinates,
   zoom: number,
}

type MapData = MapPosition & {
   markers: MarkerData[],
   polylines: PolylineData[],
   polygons: PolygonData[],
   categories: string[],
}

export type MapObject = { type: "marker", element: Marker } | { type: "polyline", element: Polyline } | { type: "polygon", element: Polygon };
type ClickHandler = (location: Coordinates | undefined, object: MapObject | undefined) => boolean;

export type Map = {
   gMap: google.maps.Map;
   infoWindow: google.maps.InfoWindow;

   position: MapPosition;
   markers: Marker[];
   polylines: Polyline[];
   polygons: Polygon[];

   categories: { [category: string]: boolean | undefined };
   onCategory: (() => void)[];

   handlers: ClickHandler[];
   currentLocation: Marker | undefined;
   id: number;
}

const defaultMapPosition = { center: { lat: 55.19, lng: 23.54 }, zoom: 7 };

export function handleClick(map: Map, event: google.maps.MapMouseEvent | undefined, object: MapObject | undefined, defaultHandler: ClickHandler) {
   const location = event?.latLng ? new google.maps.LatLng(event.latLng).toJSON() : undefined;
   [defaultHandler, ...map.handlers].reverse().every(handler => handler(location, object));
}

export function newMapId(map: Map) {
   map.id++;
   return String(map.id - 1);
}

export function mapInfo(map: Map, content: string | Element, position?: Coordinates, anchor?: Marker) {
   map.infoWindow.close();
   map.infoWindow.setPosition(position);
   map.infoWindow.setContent(content);
   map.infoWindow.open({ map: map.gMap, anchor });
}

export function centerMap(map: Map) {
   map.gMap.setCenter(map.position.center);
   map.gMap.setZoom(map.position.zoom);
}

export function filterCategory(map: Map, category: string, show: boolean) {
   map.markers.forEach(marker => marker.dataset.category === category ? (marker.map = show ? map.gMap : null) : {});
   map.polylines.forEach(polyline => polyline.get("category") === category ? polyline.setMap(show ? map.gMap : null) : {});
   map.polygons.forEach(polygon => polygon.get("category") === category ? polygon.setMap(show ? map.gMap : null) : {});
   map.categories[category] = show;
}

export function renameCategory(map: Map, prev: string, next: string) {
   map.markers.forEach(marker => marker.dataset.category === prev ? (marker.dataset.category = next) : {});
   map.polylines.forEach(polyline => polyline.get("category") === prev ? polyline.set("category", next) : {});
   map.polygons.forEach(polygon => polygon.get("category") === prev ? polygon.set("category", next) : {});
   map.categories[next] = map.categories[prev];
   delete map.categories[prev];
   map.onCategory.forEach(handler => handler());
}

export function deleteCategory(map: Map, category: string) {
   delete map.categories[category];
   map.markers.forEach(marker => marker.dataset.category === category ? (delete marker.dataset.category) : {});
   map.polylines.forEach(polyline => polyline.get("category") === category ? polyline.set("category", undefined) : {});
   map.polygons.forEach(polygon => polygon.get("category") === category ? polygon.set("category", undefined) : {});
   map.onCategory.forEach(handler => handler());
}

export function newCategory(map: Map, category: string) {
   map.categories[category] = true;
   map.onCategory.forEach(handler => handler());
}

export function clearMap(map: Map) {
   map.markers.forEach(marker => marker.map = null);
   map.polylines.forEach(polyline => polyline.setMap(null));
   map.polygons.forEach(polygon => polygon.setMap(null));
   map.position = defaultMapPosition;
}

export async function requestMap(map: Map, id: string) {
   let data: MapData | undefined;
   let error: string | undefined;

   try {
      const request = await fetch(`/api/maps/${encodeURIComponent(id)}/`);
      if (request.ok) data = await request.json();
      else if (request.status === 404) error = "Žemėlapis nerastas";
      else throw Error("Bad server response");
   } catch (err) {
      error = `Įvyko netikėta klaida: '${err}'`;
   }

   clearMap(map);
   if (data) {
      map.position = { center: data.center, zoom: data.zoom };
      map.markers = data.markers.map(marker => newMarker(map, marker));
      map.polylines = data.polylines.map(polyline => newPolyline(map, polyline));
      map.polygons = data.polygons.map(polygon => newPolygon(map, polygon));
      data.categories.forEach(category => map.categories[category] = true);
   }

   centerMap(map);
   return error;
}

export async function sendMap(map: Map, id: string, password: string, update: boolean) {
   let error: string | undefined;
   const data: MapData = { zoom: map.position.zoom, center: map.position.center, markers: [], polygons: [], polylines: [], categories: Object.keys(map.categories) };

   map.markers.forEach(marker => {
      const { id, backgroundColor, borderColor, size, text, category } = marker.dataset;
      if (marker.position && id && backgroundColor && borderColor && size) {
         const position = new google.maps.LatLng(marker.position).toJSON();
         data.markers.push({ position, id, backgroundColor, borderColor, size: Number(size), text, category });
      }
   });

   map.polylines.forEach(polyline => data.polylines.push({
      id: polyline.get("id"),
      path: polyline.getPath().getArray().map(point => point.toJSON()),
      color: polyline.get("strokeColor"),
      opacity: polyline.get("strokeOpacity"),
      weight: polyline.get("strokeWeight"),
      text: polyline.get("text"),
      category: polyline.get("category"),
   }));

   map.polygons.map(polygon => data.polygons.push({
      id: polygon.get("id"),
      paths: polygon.getPaths().getArray().map(path => path.getArray().map(point => point.toJSON())),
      backgroundColor: polygon.get("fillColor"),
      backgroundOpacity: polygon.get("fillOpacity"),
      borderColor: polygon.get("strokeColor"),
      borderOpacity: polygon.get("strokeOpacity"),
      borderWeight: polygon.get("strokeWeight"),
      text: polygon.get("text"),
      category: polygon.get("category"),
   }));

   try {
      const options: RequestInit = { method: update ? "PUT" : "POST", body: JSON.stringify({ data, password }) };
      const request = await fetch(`/api/maps/${encodeURIComponent(id)}/`, options);
      if (request.status === 401) error = "Neteisingas slaptažodis";
      else if (!request.ok) throw Error("Bad server response");
   } catch (err) {
      error = `Įvyko netikėta klaida: '${err}'`;
   }

   return error;
}

export function showLocation(map: Map) {
   if (!navigator.geolocation) return;

   const success = (location: GeolocationPosition) => {
      const position = { lat: location.coords.latitude, lng: location.coords.longitude };
      map.gMap.setCenter(position);

      if (map.currentLocation) map.currentLocation.position = position;
      else map.currentLocation = newMarker(map, { position, size: 1, text: "Jūs esate čia" });
   };

   const error = () => {
      map.currentLocation = undefined;
      mapInfo(map, "Nepavyko nustatyti Jūsų vietos", map.gMap.getCenter()?.toJSON())
   };

   navigator.geolocation.getCurrentPosition(success, error);
}

class MapConstructor {
   private librariesLoaded = false;
   private constructHandlers: ((map: Map) => void)[] = [];
   map: Map | undefined;

   async construct() {
      if (!this.librariesLoaded) {
         await new Loader({ apiKey: "AIzaSyC12jeO2PoKBqKN9AGDc158NexuwIBgaZ4", version: "weekly" }).importLibrary("marker");
         this.librariesLoaded = true;
      };

      const gMap = new google.maps.Map(document.createElement("div"), {
         mapId: "56a9d4a2f61106dc",
         streetViewControl: false,
         zoomControl: false,
         fullscreenControl: false,
         mapTypeControl: false,
         center: defaultMapPosition.center,
         zoom: defaultMapPosition.zoom,
      });

      const infoWindow = new google.maps.InfoWindow();

      this.map = {
         gMap,
         infoWindow,
         position: defaultMapPosition,
         markers: [],
         polylines: [],
         polygons: [],
         categories: {},
         onCategory: [],
         handlers: [],
         currentLocation: undefined,
         id: 0,
      };

      gMap.addListener("click", (event: google.maps.MapMouseEvent) => this.map && handleClick(this.map, event, undefined, () => {
         infoWindow.close();
         return true;
      }));

      this.constructHandlers.forEach(handler => this.map && handler(this.map));
      this.constructHandlers = [];
   }

   onConstruct(handler: (map: Map) => void) {
      this.constructHandlers.push(handler);
   }
}

export function MapElement({ mapConstructor, ...attributes }: { mapConstructor: MapConstructor } & React.HTMLAttributes<HTMLDivElement>) {
   const div = useRef<HTMLDivElement>(null);

   useEffect(() => {
      if (mapConstructor.map) div.current?.replaceChildren(mapConstructor.map.gMap.getDiv());
      else mapConstructor.onConstruct(map => div.current?.replaceChildren(map.gMap.getDiv()));
   }, [mapConstructor]);

   return <div {...attributes} ref={div} />
}

export default MapConstructor;