import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { Coordinates, Map, handleClick, mapInfo, newMapId } from "./map";

export type Polygon = google.maps.Polygon;

export type PolygonData = {
   id: string,
   paths: Coordinates[][],
   backgroundColor: string,
   backgroundOpacity: number,
   borderColor: string,
   borderWeight: number,
   borderOpacity: number,
   category?: string,
   text?: string,
}

export function newPolygon(map: Map, options: Partial<PolygonData>) {
   const { paths, backgroundColor = "#000", backgroundOpacity = 0.35, borderColor = "#000", borderWeight = 3, borderOpacity = 0.8, text, category } = options;

   const polygon = new google.maps.Polygon({
      map: map.gMap,
      paths,
      fillColor: backgroundColor,
      fillOpacity: backgroundOpacity,
      strokeColor: borderColor,
      strokeOpacity: borderOpacity,
      strokeWeight: borderWeight,
   });

   polygon.set("text", text);
   polygon.set("category", category);
   polygon.set("id", newMapId(map));

   polygon.addListener("click", (event: google.maps.MapMouseEvent | undefined) => handleClick(map, event, { type: "polygon", element: polygon }, () => {
      if (polygon.get("text") && event?.latLng) mapInfo(map, polygon.get("text"), event.latLng.toJSON());
      return true;
   }));

   return polygon;
}

function PolygonOverlay({ polygon, map }: { polygon: google.maps.Polygon, map: Map }) {
   const [lastPaths, setLastPaths] = useState<Coordinates[][]>();
   const [showOverlay, setShowOverlay] = useState(false);
   const selectCropRef = useRef<HTMLSelectElement>(null);

   const startPathEditing = useCallback(() => {
      setLastPaths(JSON.parse(JSON.stringify(polygon.getPaths().getArray().map(path => path.getArray().map(point => point.toJSON())))));
      polygon.setEditable(true);

      map.handlers.push((location, object) => {
         if (!location) return true;
         const path: Coordinates[] = JSON.parse(JSON.stringify(polygon.getPath().getArray().map(path => path.toJSON())));

         if (object?.type !== "polygon" || object.element.get("id") !== polygon.get("id")) polygon.setPath([...path, location]);
         else {
            let notPointClick = path.every((position, index) => {
               if (position.lat === location.lat && position.lng === location.lng) {
                  const container = document.createElement("div");

                  if (polygon.get("text")) {
                     const text = document.createElement("p");
                     container.appendChild(text);
                     text.innerText = polygon.get("text");
                  }

                  const button = document.createElement("button");
                  container.appendChild(button);
                  button.type = "button";
                  button.classList.add("delete-point");
                  button.innerText = "Ištrinti tašką";
                  button.onclick = () => {
                     const path: Coordinates[] = JSON.parse(JSON.stringify(polygon.getPath().getArray().map(point => point.toJSON())));
                     path.splice(index, 1);
                     polygon.setPath(path);
                     map.infoWindow.close();
                  }

                  mapInfo(map, container, location);
                  return false;
               }
               return true;
            });

            if (notPointClick && polygon.get("text")) mapInfo(map, polygon.get("text"), location);
         }

         return false;
      });
   }, [map, polygon])

   useEffect(() => { polygon.getEditable() && startPathEditing() }, [polygon, startPathEditing]);

   const savePolygonText = (event: ChangeEvent<HTMLInputElement>) => {
      if (polygon.get("text") === "") map.infoWindow.open();
      if (event.target.value === "") map.infoWindow.close();
      polygon.set("text", event.target.value.replace(/[\u00A0-\u9999<>]/g, i => '&#' + i.charCodeAt(0) + ';'));
      map.infoWindow.setContent(polygon.get("text"));
   }

   const cropPolygon = () => {
      const id = selectCropRef.current?.value;
      if (id === "none") polygon.setPaths(polygon.getPath());
      else if (id) {
         const paths = polygon.getPaths().getArray().map(path => path.getArray());
         const crop = map.polygons.find(p => p.get("id") === id);
         if (!crop) return;

         let current = -1;
         const cropPath = crop.getPath().getArray();
         paths.forEach((path, j) => path.every((point, i) => point === cropPath[i]) ? current = j : current);

         if (current === -1) polygon.setPaths([...paths, cropPath]);
         else {
            paths.splice(current, 1);
            polygon.setPaths(paths);
         }
      }
   }

   const saveCategory = (category: string) => {
      if (category === "") {
         polygon.set("category", undefined);
         polygon.setMap(map.gMap);
      } else {
         polygon.set("category", category);
         polygon.setMap(map.categories[category] !== false ? map.gMap : null);
      }
   }

   const deletePolygon = () => {
      if (lastPaths) map.handlers.pop();
      map.infoWindow.close();
      polygon.setMap(null);
      map.polygons = map.polygons.filter(p => p.get("id") !== polygon.get("id"));
      google.maps.event.trigger(map.gMap, "click");
   }

   const stopPathEditing = () => {
      setLastPaths(undefined);
      polygon.setEditable(false);
      map.handlers.pop();
   }

   const cancelPathEditing = () => {
      if (lastPaths) polygon.setPaths(lastPaths);
      stopPathEditing();
   }

   return (<>
      {showOverlay && (
         <div className="overlay">
            <div className="block">
               <label htmlFor="polygon-text">Informacija: </label>
               <input type="text" id="polygon-text" defaultValue={polygon.get("text")} onChange={savePolygonText} />
            </div>

            <div className="block">
               <label htmlFor="polygon-background-color">Pirma spalva:</label>
               <input type="color" id="polygon-background-color" defaultValue={polygon.get("fillColor")} onChange={event => polygon.set("fillColor", event.target.value)} />
            </div>

            <div className="block">
               <label htmlFor="polygon-background-opacity">Permatomumas:</label>
               <input type="number" id="polygon-background-opacity" defaultValue={polygon.get("fillOpacity")} onChange={event => polygon.set("fillOpacity", Number(event.target.value))} min={0} max={1} />
            </div>

            <div className="block">
               <label htmlFor="polygon-border-color">Antra spalva:</label>
               <input type="color" id="polygon-border-color" defaultValue={polygon.get("strokeColor")} onChange={event => polygon.set("strokeColor", event.target.value)} />
            </div>

            <div className="block">
               <label htmlFor="polygon-border-weight">Storis:</label>
               <input type="number" id="polygon-border-weight" defaultValue={polygon.get("strokeWeight")} onChange={event => polygon.set("strokeWeight", Number(event.target.value))} min={0} />
            </div>

            <div className="block">
               <label htmlFor="polygon-border-opacity">Permatomumas:</label>
               <input type="number" id="polygon-border-opacity" defaultValue={polygon.get("strokeOpacity")} onChange={event => polygon.set("strokeOpacity", Number(event.target.value))} min={0} max={1} />
            </div>

            <div className="block">
               <label htmlFor="polygon-select-crop">Persidengti su:</label>
               <select id="polygon-select-crop" ref={selectCropRef} >
                  <option value={"none"} key={"none"}>-</option>
                  {map.polygons.map(p => p.get("id") !== polygon.get("id") && p.get("text") ? (
                     <option key={p.get("id")} value={p.get("id")} >{p.get("text")}</option>
                  ) : undefined)}
               </select>
               <button type="button" onClick={cropPolygon}>Perdengti</button>
            </div>

            <div className="block">
               <label htmlFor="polygon-category">Kategorija:</label>
               <select id="polygon-category" defaultValue={polygon.get("category")} onChange={event => saveCategory(event.target.value)}>
                  <option value="">-</option>
                  {Object.keys(map.categories).map(category => <option key={category} value={category}>{category}</option>)}
               </select>
            </div>

            <button type="button" onClick={() => setShowOverlay(false)}>Uždaryti</button>
         </div>
      )}

      {!showOverlay && (
         <div className="map-object-controls">
            <button type="button" onClick={() => setShowOverlay(true)}>
               <span className="material-symbols-outlined" title="Redaguoti figūrą">edit</span>
            </button>

            {!lastPaths ? (
               <button type="button" onClick={startPathEditing}>
                  <span className="material-symbols-outlined" title="Redaguoti figūros formą">location_searching</span>
               </button>
            ) : (
               <>
                  <button type="button" onClick={stopPathEditing}>
                     <span className="material-symbols-outlined" title="Išsaugoti figūros formą">check</span>
                  </button>

                  <button type="button" onClick={cancelPathEditing}>
                     <span className="material-symbols-outlined" title="Atšaukti figūros formos pakeitimus">close</span>
                  </button>
               </>
            )}

            <button type="button" onClick={deletePolygon}>
               <span className="material-symbols-outlined" title="Išrinti figūrą">delete</span>
            </button>
         </div>
      )}
   </>);
}

export default PolygonOverlay;