import { NavLink } from "react-router-dom";

function Header() {
   return (
      <header>
         <div>
            <h1><NavLink className={({ isActive }) => isActive ? "active" : undefined} to="/">MapX</NavLink></h1>
            <nav>
               <ul>
                  <li><NavLink className={({ isActive }) => isActive ? "active" : undefined} to="/explore">Žemėlapiai</NavLink></li>
               </ul>
            </nav>
         </div>
      </header>
   )
}

export default Header;