import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Coordinates, Map, handleClick, mapInfo, newMapId } from "./map";

export type Marker = google.maps.marker.AdvancedMarkerElement;

export type MarkerData = {
   id: string,
   position: Coordinates,
   size: number,
   backgroundColor: string,
   borderColor: string,
   category?: string,
   text?: string,
}

export function newMarker(map: Map, options: Partial<MarkerData>) {
   const { position, size = 0.9, backgroundColor = "#ea4335", borderColor = "#b31412", text, category } = options;

   const marker = new google.maps.marker.AdvancedMarkerElement({
      map: map.gMap,
      position,
      title: text,
      content: new google.maps.marker.PinElement({
         background: backgroundColor,
         borderColor: borderColor,
         glyphColor: borderColor,
         scale: size,
      }).element,
   });

   marker.dataset.id = newMapId(map);
   marker.dataset.backgroundColor = backgroundColor;
   marker.dataset.borderColor = borderColor;
   marker.dataset.size = String(size);
   if (category) marker.dataset.category = category;
   if (text) marker.dataset.text = text;

   marker.addListener("click", (event: google.maps.MapMouseEvent) => handleClick(map, event, { type: "marker", element: marker }, () => {
      if (marker.dataset.text) mapInfo(map, marker.dataset.text, undefined, marker);
      return true;
   }));

   return marker;
}

function MarkerOverlay({ marker, map }: { map: Map, marker: Marker }) {
   const [lastPosition, setLastPosition] = useState<Coordinates>();
   const [showOverlay, setShowOverlay] = useState(false);

   const startPositionEditing = useCallback(() => {
      const position = marker.position;
      if (!position) return;

      setLastPosition(new google.maps.LatLng(position).toJSON());
      marker.gmpDraggable = true;
      map.handlers.push((location) => {
         if (!marker.position || !location) return true;

         const position = new google.maps.LatLng(marker.position);
         const loc = new google.maps.LatLng(location);

         if (!position.equals(loc)) {
            marker.position = location;
            google.maps.event.trigger(marker, "click");
         } else if (marker.dataset.text) mapInfo(map, marker.dataset.text, undefined, marker);

         return false;
      })
   }, [map, marker])

   useEffect(() => { marker.gmpDraggable && startPositionEditing() }, [marker, startPositionEditing]);

   const saveMarkerText = (event: ChangeEvent<HTMLInputElement>) => {
      if (marker.dataset.text === "" || marker.dataset.text === undefined) map.infoWindow.open();
      if (event.target.value === "") map.infoWindow.close();
      marker.title = event.target.value;
      marker.dataset.text = event.target.value.replace(/[\u00A0-\u9999<>]/g, i => '&#' + i.charCodeAt(0) + ';');
      map.infoWindow.setContent(marker.dataset.text);
   }

   const saveMarkerPin = ({ size, backgroundColor, borderColor }: { size?: number, backgroundColor?: string, borderColor?: string }) => {
      if (size) marker.dataset.size = String(size);
      if (backgroundColor) marker.dataset.backgroundColor = backgroundColor;
      if (borderColor) marker.dataset.borderColor = borderColor;

      marker.content = new google.maps.marker.PinElement({
         background: marker.dataset.backgroundColor,
         borderColor: marker.dataset.borderColor,
         glyphColor: marker.dataset.borderColor,
         scale: Number(marker.dataset.size),
      }).element;
   }

   const saveCategory = (category: string) => {
      if (category === "") {
         delete marker.dataset.category;
         marker.map = map.gMap;
      } else {
         marker.dataset.category = category;
         marker.map = map.categories[category] !== false ? map.gMap : null;
      }
   }

   const deleteMarker = () => {
      if (lastPosition) map.handlers.pop();
      map.infoWindow.close();
      marker.map = null;
      map.markers = map.markers.filter(m => m.dataset.id !== marker.dataset.id);
      google.maps.event.trigger(map.gMap, "click");
   }

   const stopPositionEditing = () => {
      setLastPosition(undefined);
      marker.gmpDraggable = false;
      map.handlers.pop();
   }

   const cancelPositionEditing = () => {
      marker.position = lastPosition;
      stopPositionEditing();
   }

   return (<>
      {showOverlay && (
         <div className="overlay">
            <div className="block">
               <label htmlFor="marker-text">Pavadinimas:</label>
               <input type="text" id="marker-text" defaultValue={marker.dataset.text} onChange={saveMarkerText} />
            </div>

            <div className="block">
               <label htmlFor="marker-size">Dydis:</label>
               <input type="number" id="marker-size" defaultValue={marker.dataset.size} onChange={event => saveMarkerPin({ size: Number(event.target.value) })} min={0} max={1} />
            </div>

            <div className="block">
               <label htmlFor="marker-background-color">Pirma spalva:</label>
               <input type="color" id="marker-background-color" defaultValue={marker.dataset.backgroundColor} onChange={event => saveMarkerPin({ backgroundColor: event.target.value })} />
            </div>

            <div className="block">
               <label htmlFor="marker-border-color">Antra spalva:</label>
               <input type="color" id="marker-border-color" defaultValue={marker.dataset.borderColor} onChange={event => saveMarkerPin({ borderColor: event.target.value })} />
            </div>

            <div className="block">
               <label htmlFor="marker-category">Kategorija:</label>
               <select id="marker-category" defaultValue={marker.dataset.category} onChange={event => saveCategory(event.target.value)}>
                  <option value="">-</option>
                  {Object.keys(map.categories).map(category => <option key={category} value={category}>{category}</option>)}
               </select>
            </div>

            <button type="button" onClick={() => setShowOverlay(false)}>Uždaryti</button>
         </div>
      )}

      {!showOverlay && (
         <div className="map-object-controls">
            <button type="button" onClick={() => setShowOverlay(true)}>
               <span className="material-symbols-outlined" title="Redaguoti žymeklį">edit</span>
            </button>

            {!lastPosition ? (
               <button type="button" onClick={startPositionEditing}>
                  <span className="material-symbols-outlined" title="Redaguoti žymeklio vietą">location_searching</span>
               </button>
            ) : (
               <>
                  <button type="button" onClick={stopPositionEditing}>
                     <span className="material-symbols-outlined" title="Išsaugoti žymeklio vietą">check</span>
                  </button>

                  <button type="button" onClick={cancelPositionEditing}>
                     <span className="material-symbols-outlined" title="Atšaukti žymeklio vietos pakeitimus">close</span>
                  </button>
               </>
            )}

            <button type="button" onClick={deleteMarker}>
               <span className="material-symbols-outlined" title="Ištrinti žymeklį">delete</span>
            </button>
         </div>
      )}
   </>);
}

export default MarkerOverlay;