import { createRoot } from 'react-dom/client';
import App from './components/App';
import "./main.css";

// Don't use StrictMode because google.maps would create markers and draw figures twice

const root = document.getElementById("root")!;
const reactRoot = createRoot(root);
reactRoot.render(<App />);


