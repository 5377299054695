import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import MapConstructor from "../utils/map";

import Header from "./Header";
const Edit = lazy(() => import("./Edit"));
const View = lazy(() => import("./View"));
const Landing = lazy(() => import("./Landing"));

function App() {
   const mc = new MapConstructor();

   return (
      <BrowserRouter>
         <Header />
         <Suspense fallback={<></>}>
            <Routes>
               <Route path="/new" element={<Edit mapConstructor={mc} />} />
               <Route path="/explore" element={<View mapConstructor={mc} />} />
               <Route path="/:id/edit" element={<Edit mapConstructor={mc} />} />
               <Route path="/:id" element={<View mapConstructor={mc} />} />
               <Route path="/" element={<Landing />} />
               <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
         </Suspense>
      </BrowserRouter>
   );
}

export default App;
