import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Coordinates, Map, handleClick, mapInfo, newMapId } from "./map";

export type Polyline = google.maps.Polyline;

export type PolylineData = {
   id: string,
   path: Coordinates[],
   color: string,
   weight: number,
   opacity: number,
   category?: string,
   text?: string,
}

export function newPolyline(map: Map, options: Partial<PolylineData>) {
   const { path, color = "#000", weight = 3, opacity = 1, text, category } = options;

   const polyline = new google.maps.Polyline({
      map: map.gMap,
      path,
      strokeColor: color,
      strokeOpacity: opacity,
      strokeWeight: weight,
   });

   polyline.set("text", text);
   polyline.set("category", category);
   polyline.set("id", newMapId(map));

   polyline.addListener("click", (event: google.maps.MapMouseEvent | undefined) => handleClick(map, event, { type: "polyline", element: polyline }, () => {
      if (polyline.get("text") && event?.latLng) mapInfo(map, polyline.get("text"), event.latLng.toJSON());
      return true;
   }));

   return polyline;
}

function PolylineOverlay({ polyline, map }: { polyline: Polyline, map: Map }) {
   const [lastPath, setLastPath] = useState<Coordinates[]>();
   const [showOverlay, setShowOverlay] = useState(false);

   const startPathEditing = useCallback(() => {
      setLastPath(JSON.parse(JSON.stringify(polyline.getPath().getArray().map(point => point.toJSON()))));
      polyline.setEditable(true);

      map.handlers.push((location, object) => {
         if (!location) return true;
         const path: Coordinates[] = JSON.parse(JSON.stringify(polyline.getPath().getArray().map(point => point.toJSON())));

         if (object?.type !== "polyline" || object.element.get("id") !== polyline.get("id")) polyline.setPath([...path, location]);
         else {
            let notPointClick = path.every((position, index) => {
               if (position.lat === location.lat && position.lng === location.lng) {
                  const container = document.createElement("div");

                  if (polyline.get("text")) {
                     const text = document.createElement("p");
                     container.appendChild(text);
                     text.innerText = polyline.get("text");
                  }

                  const button = document.createElement("button");
                  container.appendChild(button);
                  button.classList.add("delete-point");
                  button.type = "button";
                  button.innerText = "Ištrinti tašką";
                  button.onclick = () => {
                     const path: Coordinates[] = JSON.parse(JSON.stringify(polyline.getPath().getArray().map(point => point.toJSON())));
                     path.splice(index, 1);
                     polyline.setPath(path);
                     map.infoWindow.close();
                  }

                  mapInfo(map, container, location);
                  return false;
               }
               return true;
            });

            if (notPointClick && polyline.get("text")) mapInfo(map, polyline.get("text"), location);
         }

         return false;
      });
   }, [map, polyline])

   useEffect(() => { polyline.getEditable() && startPathEditing() }, [polyline, startPathEditing]);

   const savePolylineText = (event: ChangeEvent<HTMLInputElement>) => {
      if (polyline.get("text") === "") map.infoWindow.open();
      if (event.target.value === "") map.infoWindow.close();
      polyline.set("text", event.target.value.replace(/[\u00A0-\u9999<>]/g, i => '&#' + i.charCodeAt(0) + ';'));
      map.infoWindow.setContent(polyline.get("text"));
   }

   const saveCategory = (category: string) => {
      if (category === "") {
         polyline.set("category", undefined);
         polyline.setMap(map.gMap);
      } else {
         polyline.set("category", category);
         polyline.setMap(map.categories[category] !== false ? map.gMap : null);
      }
   }

   const deletePolyline = () => {
      if (lastPath) map.handlers.pop();
      map.infoWindow.close();
      polyline.setMap(null);
      map.polylines = map.polylines.filter(p => p.get("id") !== polyline.get("id"));
      google.maps.event.trigger(map.gMap, "click");
   }

   const stopPathEditing = () => {
      setLastPath(undefined);
      polyline.setEditable(false);
      map.handlers.pop();
   }

   const cancelPathEditing = () => {
      if (lastPath) polyline.setPath(lastPath);
      stopPathEditing();
   }

   return (<>
      {showOverlay && (
         <div className="overlay">
            <div className="block">
               <label htmlFor="polyline-text">Informacija:</label>
               <input type="text" id="polyline-text" defaultValue={polyline.get("text")} onChange={savePolylineText} />
            </div>

            <div className="block">
               <label htmlFor="polyline-color">Spalva:</label>
               <input type="color" id="polyline-color" defaultValue={polyline.get("strokeColor")} onChange={event => polyline.set("strokeColor", event.target.value)} />
            </div>

            <div className="block">
               <label htmlFor="polyline-weight">Storis:</label>
               <input type="number" id="polyline-weight" defaultValue={polyline.get("strokeWeight")} onChange={event => polyline.set("strokeWeight", Number(event.target.value))} min={0} />
            </div>

            <div className="block">
               <label htmlFor="polyline-opacity">Permatomumas:</label>
               <input type="number" id="polyline-opacity" defaultValue={polyline.get("strokeOpacity")} onChange={event => polyline.set("strokeOpacity", Number(event.target.value))} min={0} max={1} />
            </div>

            <div className="block">
               <label htmlFor="polyline-category">Kategorija:</label>
               <select id="polyline-category" defaultValue={polyline.get("category")} onChange={event => saveCategory(event.target.value)}>
                  <option value="">-</option>
                  {Object.keys(map.categories).map(category => <option key={category} value={category}>{category}</option>)}
               </select>
            </div>

            <button type="button" onClick={() => setShowOverlay(false)}>Uždaryti</button>
         </div>
      )}

      {!showOverlay && (
         <div className="map-object-controls">
            <button type="button" onClick={() => setShowOverlay(true)}>
               <span className="material-symbols-outlined" title="Redaguoti liniją">edit</span>
            </button>

            {!lastPath ? (
               <button type="button" onClick={startPathEditing}>
                  <span className="material-symbols-outlined" title="Redaguoti linijos taškus">location_searching</span>
               </button>
            ) : (
               <>
                  <button type="button" onClick={stopPathEditing}>
                     <span className="material-symbols-outlined" title="Išsaugoti linijos taškus">check</span>
                  </button>

                  <button type="button" onClick={cancelPathEditing}>
                     <span className="material-symbols-outlined" title="Atšaukti linijos taškų pakeitimus">close</span>
                  </button>
               </>
            )}

            <button type="button" onClick={deletePolyline}>
               <span className="material-symbols-outlined" title="Ištrinti liniją">delete</span>
            </button>
         </div>
      )}
   </>);
}

export default PolylineOverlay;